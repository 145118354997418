import React, {
  FC,
  useState,
  useEffect,
  useContext,
} from 'react';
import { Observer } from 'mobx-react-lite';

import { InterruptionItem } from '@/newcar/core/store/interruptionStore';
import InterruptionStoreContext from '@/newcar/ui/common/context/InterruptionStoreContext';
import Show from '@/newcar/ui/molecule/Show';

// topのstyle読込
import * as styles from '@/newcar/ui/top/template/index.module.styl';
import * as stylesInterrupt from '@/newcar/ui/top/organism/InterruptionItems.module.styl';

interface CardLengthState {
  isOverLength: boolean,
}

const InterruptionItems: FC = () => {
  const interruptionStore = useContext(InterruptionStoreContext);

  // カードの枚数が規定数を超えているかどうか判定
  const [stateOverFour, setStateLengthOver] = useState<CardLengthState>({
    isOverLength: false,
  });
  const arrayLengthHandler = () => {
    setStateLengthOver({
      isOverLength: true,
    });
  };

  useEffect(() => {
    if (interruptionStore.getItems.length >= 4) {
      arrayLengthHandler();
    }
  }, []);

  const planText = (item: InterruptionItem) => {
    let text = `${item.term}年／グレード ${item.grade}`;
    if (item.color) {
      text += `／カラー ${item.color}`;
    }
    if (item.option) {
      text += `／オプション装備 ${item.option.replaceAll(' |', '、')}`;
    }
    if (item.maintenance) {
      text += `／メンテナンスプラン ${item.maintenance}`;
    }
    return text;
  };

  return (
    <Observer>
      {() => (
        <div>
          <Show when={interruptionStore.getItems.length > 0}>
            <div className={`is-relative ${stylesInterrupt.interruptionContainer}`}>
              <h2
                className={`has-text-centered ${styles.contentsTitle} ${styles.isInterruption} ${styles.big}`}
                itemProp="InterruptedContents"
              >
                ご入力途中のお申込みがございます
              </h2>
              <div className={`
                  ${stylesInterrupt.interruptionInner}
                  ${stateOverFour.isOverLength ? `${stylesInterrupt.isOverLength}` : ''}
                `}
              >
                {interruptionStore.getItems.map((item, index) => (
                  <div
                    key={index.toString()}
                    className={`
                      ${stylesInterrupt.interruptionCard}
                      ${stateOverFour.isOverLength ? `${stylesInterrupt.isOverLength}` : ''}
                    `}
                  >
                    <a
                      href={item.href}
                      className={`is-relative ${stylesInterrupt.interruptionLink}`}
                    >
                      <figure className={`image ${stylesInterrupt.interruptionThumbnail}`}>
                        <img
                          src={item.src}
                          className="recommend-img"
                          alt={item.carName}
                          width="72"
                          height="41"
                          loading="lazy"
                        />
                      </figure>
                      <dl className={stylesInterrupt.interruptionInformation}>
                        <dt className={stylesInterrupt.interruptionName}>{`${item.maker} ${item.carName}`}</dt>
                        <dd className={stylesInterrupt.interruptionDetail}>
                          月額
                          {item.price.toLocaleString()}
                          円(税込)
                          <br />
                          <span className={stylesInterrupt.interruptionPlan}>
                            {planText(item)}
                          </span>
                        </dd>
                      </dl>
                    </a>
                    <button
                      className={stylesInterrupt.interruptionClose}
                      type="button"
                      tabIndex={0}
                      onClick={() => interruptionStore.removeByIndex(index)}
                    >
                      <span className={stylesInterrupt.interruptionCloseText}>x</span>
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </Show>
        </div>
      )}
    </Observer>
  );
};

export default InterruptionItems;
