// extracted by mini-css-extract-plugin
export const interruptionContainer = "InterruptionItems-module--interruption-container--3rgDQ";
export const interruptionInner = "InterruptionItems-module--interruption-inner--4LSr6";
export const isOverLength = "InterruptionItems-module--is-over-length--kflSE";
export const interruptionLink = "InterruptionItems-module--interruption-link--3zpdu";
export const interruptionThumbnail = "InterruptionItems-module--interruption-thumbnail--RUoeq";
export const interruptionCard = "InterruptionItems-module--interruption-card--1SAyN";
export const interruptionInformation = "InterruptionItems-module--interruption-information--3ZxMX";
export const interruptionName = "InterruptionItems-module--interruption-name--26W6G";
export const interruptionDetail = "InterruptionItems-module--interruption-detail--3i-gV";
export const interruptionClose = "InterruptionItems-module--interruption-close--8VbVD";
export const interruptionCloseText = "InterruptionItems-module--interruption-close-text--1yrFD";
export const interruptionPlan = "InterruptionItems-module--interruption-plan--TvmC0";